import React, {Component} from 'react';
import styled from 'styled-components';
import {FaChevronLeft} from 'react-icons/fa';
import Header from '../component/Header';

const Page = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;


const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

class Popup extends Component {
    renderLeftIcon() {
        return <FaChevronLeft/>;
    }

    _rli = (...args) => this.renderLeftIcon(...args);

    onLeftClick() {
        const {history} = this.props;

        history.goBack();
    }

    _olc = (...args) => this.onLeftClick(...args);

    renderRightIcon() {
        return '';
    }

    _rri = (...args) => this.renderRightIcon(...args);

    onRightClick() {
    }

    _orc = (...args) => this.onRightClick(...args);

    renderContent() {
        return 'Looks like you forgot to override Popup.renderContent()';
    }

    render() {
        return (
            <Page>
                <Header
                    renderLeftIcon={this._rli}
                    onLeftClick={this._olc}
                    renderRightIcon={this._rri}
                    onRightClick={this._orc}
                />
                <Content>
                    {this.renderContent()}
                </Content>
            </Page>
        )
    }
}

export default Popup;