import React from 'react';
import styled from 'styled-components';
import {FaCamera} from 'react-icons/fa';

const PlantPhoto = styled.div`
    background-color: #ddd;
    background-image: ${({image}) => `url(${image})`};
    background-size: cover;
    background-position: center center;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 30px;
`;

const PlantName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: #666;
`;

const Plant = ({plant, onSelect}) => {
    const p = plant.plant || plant;

    const image = p.image && p.image.url;

    return (
        <div onClick={() => onSelect(plant)}>
            <PlantPhoto image={image}>
                {!image && <FaCamera/>}
            </PlantPhoto>
            <PlantName>{p.commonName}</PlantName>
        </div>
    );
};

export default Plant;