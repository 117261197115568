const theme = {
    heading: {color: '#fff', background: '#f90'},

    tab: {color:'#3a6047', background: '#efefef',
        selected: {color: '#fff', background: '#777'}
    },

    highlight: {color: '#060', background: '#fff'},

    subtle: {color: '#999', background: 'transparent', border: '#999'},

    input: {color: '#000', background: '#f4f4f4', size: '20px'},

    ok: {color: '#fff', background: '#f90', fontWeight: 'bold'},
    warn: {color: '#fff', background: '#900'},
    disabled: {color: '#fff', background: '#ccc'},

    overlay: {
        opacity: 0.25,
        timeout: 150
    },

    hover: {color: 'inherit', background: 'rgba(255,153,0,.15)'}
};

export default theme;