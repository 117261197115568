import React, {Component} from 'react';

import ApolloClient from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';
import gql from 'graphql-tag';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import styled, {ThemeProvider} from 'styled-components';

import AnalyticsTracker from './component/AnalyticsTracker';

import Admin from './screen/Admin';

import Intro from './screen/Intro';
import Login from './screen/Login';
import Gardens from './screen/Gardens';
import Messaging from './screen/Messaging';
import Messages from './screen/Messaging/Messages';
import PlantCare from './screen/PlantCare';
import Weather from './screen/Weather';

import Garden from './screen/Gardens/Garden';
import AddPlant from './component/garden/AddPlant';

import Footer from './component/Footer';

import Spinner from './component/Spinner';

import theme from './theme';
import {useQuery} from "@apollo/react-hooks";


const client = new ApolloClient({
    uri: `/graphql`,
    request: operation => {
        const jwt = window.localStorage.getItem('jwt');

        if (jwt) {
            operation.setContext({
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
        }
    }
});

const Page = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    & > ${Spinner} {
        line-height: 100vh;
    }
`;

const Content = styled(Switch)`
    flex: 1;
    display: flex;
`;

class App extends Component {
    renderSpinner() {
        return (
            <Page>
                <Spinner/>
            </Page>
        );
    }

    Logout = () => {
        if (window.sessionStorage['impersonate']) {
            window.sessionStorage.removeItem('impersonate');
        }
        else {
            window.localStorage.removeItem('jwt');
        }

        window.location = '/';
    };

    render() {
        const {loading, error, refetch, networkStatus} = this.props;

        if (loading || networkStatus===4) {
            return (
                <Page>
                    <Spinner/>
                </Page>
            );
        }

        if (error) {
            return (
                <Router>
                    <Page>
                        <AnalyticsTracker/>
                        <Content>
                            <Route path="/intro" component={Intro}/>
                            <Route path="/login" render={props => <Login {...props} onLogin={refetch}/>}/>
                            <Redirect to={'/intro'}/>
                        </Content>
                    </Page>
                </Router>
            );
        }

        return (
            <Router>
                <Page>
                    <AnalyticsTracker/>
                    <Content>
                        <Route path="/gardens/:id/add-plant" component={AddPlant}/>
                        <Route path="/gardens/:id" component={Garden}/>
                        <Route path="/gardens" component={Gardens}/>
                        <Route path="/messages/:id" component={Messages}/>
                        <Route path="/messages" component={Messaging}/>
                        <Route path="/plant-care/:wikipediaTitle?" component={PlantCare}/>
                        <Route path="/weather" component={Weather}/>
                        <Route path="/logout" component={this.Logout}/>
                        <Route path="/admin" component={Admin}/>
                        <Redirect to={'/gardens'}/>
                    </Content>
                    <Footer/>
                </Page>
            </Router>
        );
    }
}

const VIEWER = gql`
{
    viewer {
        firstName
        lastName
        avatar {
            url
        }
        pro
        admin
        messageCounts {
            nodes {
                unread
            }
        }
    }
}
`;

const Viewer = props => {
    const result = useQuery(VIEWER, {
        fetchPolicy: 'cache-and-network'
    });

    return (
        <App {...props} {...result}/>
    )
};

export default props => (
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <Viewer {...props}/>
        </ThemeProvider>
    </ApolloProvider>
);
