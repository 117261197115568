import styled from 'styled-components';

const Item = styled.div`
    width: 175px;
    padding: 10px;
    cursor: pointer;
    
    @media (max-width: 375px) {
        width: 160px;
    }
    
    @media (max-width: 320px) {
        width: 140px;
    }
`;

export default Item;