import React from 'react';
import styled, {keyframes} from 'styled-components';

import PlantList from '../PlantList';
import Popup from '../Popup';
import Heading from '../Heading';
import AutocompletePlant from '../AutocompletePlant';
import Dialog from '../Dialog';
import PlantStage from '../PlantStage';
import Buttons from '../Buttons';
import Button from '../Button';
import ScrollView from '../ScrollView';
import Input from '../Input';

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const fade = keyframes`
    from { opacity: 0.1; }
    to { opacity: 1.0; }
`;
const Loading = styled.div.attrs({
    children: 'Loading...'
})`
    text-align: center;
    flex: 1;
    font-size: 32px;
    color: #666;
    opacity: 0.1;
    margin-top: 20px;
    
    animation: ${fade} 500ms alternate infinite;
`;

const PlantNow = styled.div.attrs({
    children: ({list, selectPlant}) => {
        if (!(list && list.length > 0))
            return <Loading/>;

        return [
            <Heading key="heading">What To Plant Now</Heading>,
            <ScrollView key="scrollview">
                <PlantList key="list" plants={list} onSelect={selectPlant}/>
            </ScrollView>
        ];
    }
})`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Quantity = styled.div`
    ${Input} {
        width: 2em;
    }
    
    margin-bottom: 1em;
`;

const PlantStageLabel = styled.div`
`;

class AddPlant extends Popup {
    state = {
        plantNow: null,
        addPlantQuantity: 1
    };

    selectPlant = plant => {
        this.setState({plant});
    };

    clearSelection = () => {
        this.setState({plant:null})
    };

    addPlantQuantity = e => {
        const addPlantQuantity = e.target.value;
        this.setState({addPlantQuantity});
    };

    addPlant = stage => {
        const {plant, addPlantQuantity: quantity} = this.state;

        // TODO: figure out what the start date should be based on the stage
        const startDate = stage < 2 ? null : new Date();

        const {zone, zone:{plants=[]}, saveZone, history} = this.props;

        if (!plants.find(p => p.itemId === plant.itemId)) {
            saveZone(Object.assign({}, zone, {
                plants: plants.concat(Object.assign({
                    startDate,
                    quantity
                }, plant))
            }));
        }

        history.goBack();
    };

    renderContent() {
        const {zone} = this.props;
        const {plantNow, plant, addPlantQuantity} = this.state;

        return (
            <Content>
                <AutocompletePlant
                    zone={zone}
                    placeholder="Search Plants..."
                    onSelect={this.selectPlant}
                />
                <PlantNow list={plantNow} selectPlant={this.selectPlant}/>
                {plant &&
                    <Dialog>
                        <h3>Add Plant</h3>
                        <Quantity>
                            Number of Plants:
                            <Input type="number" value={addPlantQuantity} min={1} onChange={this.addPlantQuantity}/>
                        </Quantity>
                        <PlantStageLabel>Select Planting Stage</PlantStageLabel>
                        <PlantStage onSelect={this.addPlant}/>
                        <Buttons>
                            <Button type="warn" onClick={this.clearSelection}>Cancel</Button>
                        </Buttons>
                    </Dialog>
                }
            </Content>
        )
    }
}


export default AddPlant;
