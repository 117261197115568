import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import {FaChevronLeft} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';

import Header from '../component/Header';
import Heading from '../component/Heading';
import AutocompletePlant from '../component/AutocompletePlant';
import PlantList from '../component/PlantList';
import ScrollView from '../component/ScrollView';
import {useQuery} from "@apollo/react-hooks";

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    display: flex;
`;

const WikipediaWrapper = styled.div`
    flex-grow: 1;
    
    @supports (-webkit-overflow-scrolling: touch) {
        /* CSS specific to iOS devices */
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
        -webkit-overflow-scrolling: touch;
    }
        
    @supports not (-webkit-overflow-scrolling: touch) {
        /* CSS specific to iOS devices */
        display: flex;
    }
`;

const Wikipedia = styled.iframe.attrs({
    frameBorder: 0,
    marginHeight: 0,
    marginWidth: 0
})`
    width: 100%;
    
    @supports not (-webkit-overflow-scrolling: touch) {
        /* CSS specific to iOS devices */
        flex-grow: 1;
    }
`;

const PlantCare = ({gardens, match:{params:{wikipediaTitle}}, history:{goBack}}) => {
    const history = useHistory();

    const onSelect = plant => {
        const {wikipediaTitle} = plant;

        history.push(`/plant-care/${encodeURIComponent(wikipediaTitle)}`);
    };

    const getPlants = () => {
        const map = {};
        gardens.forEach(({gardenPlants}) => {
            gardenPlants.nodes.forEach(({plant}) => map[plant.commonName] = plant);
        });

        const plantNames = Object.keys(map);
        plantNames.sort();

        const plants = plantNames.map(name => map[name]);

        return (
            <PlantList plants={plants} onSelect={onSelect}/>
        );
    };

    const renderWikipediaPage = () => {
        const wikipedia = wikipediaTitle ? <Wikipedia src={`https://en.wikipedia.org/wiki/${wikipediaTitle}#Cultivation`}/> : <div>no data</div>;

        return (
            <Wrapper>
                <Header
                    renderLeftIcon={wikipediaTitle && <FaChevronLeft/>}
                    onLeftClick={goBack}
                />
                <WikipediaWrapper>
                    {wikipedia}
                </WikipediaWrapper>
            </Wrapper>
        );
    };

    if (wikipediaTitle)
        return renderWikipediaPage();

    return (
        <Wrapper>
            <Header/>
            <AutocompletePlant
                placeholder="Search Plants..."
                onSelect={onSelect}
                filter={({wikipediaTitle}) => !!wikipediaTitle}
            />
            <Heading>My Plants</Heading>
            <ScrollView>
            {getPlants()}
            </ScrollView>
        </Wrapper>
    );
};

const GARDEN_QUERY = gql`
{
  viewer {
    gardens {
      nodes {
        id
        name
        image {
          url
        }
        gardenPlants {
          nodes {
            plant {
              id
              commonName
              wikipediaTitle
              image {
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

export default props => {
    const {data={}} = useQuery(GARDEN_QUERY);

    const {viewer:{gardens:{nodes:gardens}}={gardens:{nodes:[]}}} = data;

    return (
        <PlantCare {...props} gardens={gardens}/>
    );
};
