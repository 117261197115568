import React from 'react';
import styled from 'styled-components';

import Overlay from './Overlay';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    background: #fff;
    padding: 20px;
    z-index: 32768;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0,0,0,.25);
    max-width: 90vw;
    text-align: center;
`;

const Dialog = ({children, className, style}) => (
    <Wrapper>
        <Overlay/>
        <Content className={className} style={style}>
            {children}
        </Content>
    </Wrapper>
);

export default Dialog;