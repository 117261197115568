import {withRouter} from 'react-router-dom';

import styled from 'styled-components';

const Button = styled.button.attrs(({onClick:originOnClick, ...props}) => Object.assign({}, props,{
    onClick: (e) => {
            const {to, history} = props;

            if (to) {
                e.preventDefault();
                history.push(to);
            }
            else if (typeof originOnClick === 'function') {
                originOnClick(e);
            }
        }
    })
)`
    background-color: ${({theme, type='ok', disabled, inactive}) => theme[disabled||inactive ? 'disabled' : type].background};
    color: ${({theme, type='ok', disabled, inactive}) => theme[disabled||inactive ? 'disabled' : type].color};
    cursor: pointer;
    box-sizing: border-box;
    height: 40px;
    font-size: 24px;
    font-weight: ${({theme, type='ok'}) => theme[type].fontWeight || 'normal'};
    border: ${({theme, type='ok', disabled, inactive}) => `1px solid ${theme[disabled||inactive ? 'disabled' : type].border || theme[disabled||inactive ? 'disabled' : type].background}`};
    border-radius: 20px;
    padding: 3px 20px;
    margin: 2px -5px 10px;
    flex-shrink: 0;
`;

export default withRouter(Button);
