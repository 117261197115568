import React from 'react';
import styled from 'styled-components';

import Logo from '../image/logo.png';

import Buttons from '../component/Buttons';
import Button from '../component/Button';

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("/image/veggies.jpg") center center;
    background-size: cover;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255,255,255,.8);
    
    padding: 2vw 10vw;
    
    img {
        width: 80vw;
        max-width: 330px;
    }
    
    p {
        text-align: center;
        font-size: 3.5vw;
    }
    
    @media (max-width: 600px) {
        p {
            font-size: 20px;
        }
    }
    
    @media (min-width: 1000px) {
        p {
            width: 800px;
            font-size: 35px;
        }
    }
`;

const Intro = () => (
    <Wrapper>
        <Content>
            <img src={Logo} alt="Agriscaping"/>
            <p>
                Agriscaping is an innovative solution helping people transform their home front or back yards
                into
                beautiful and productive food gardens.
            </p>
            <Buttons>
                <Button to="/login">Get Started</Button>
            </Buttons>
        </Content>
    </Wrapper>
);

export default Intro;