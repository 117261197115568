import styled from 'styled-components';

const Input = styled.input`
    border: none;
    font-size: ${({theme:{input:{size}}}) => size};
    margin: 5px 0;
    padding: 4px 8px;
    border-bottom: 1px solid #999;
`;

export default Input;