import React, {Component, createElement} from 'react';
import styled, {withTheme} from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {FaCheck,FaPlus,FaChevronRight,FaUser,FaWrench,FaToggleOn} from 'react-icons/fa';
import gql from 'graphql-tag';

import {uploadPhoto} from '../../api';
import ScrollView from '../../component/ScrollView';
import ListItem, {IconWrapper} from '../../component/ListItem';
import Dialog from '../../component/Dialog';
import Input from '../../component/Input';
import Button from '../../component/Button';
import Buttons from '../../component/Buttons';

import background from '../../image/my-garden-header.jpg';
import {useQuery} from "@apollo/react-hooks";

const Page = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled.div`
    background-image: url(${background});
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 4px #000;
    padding: 2vw 0;
`;

const Avatar = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #333;
    background-image: ${({image}) => `url(${image})`};
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 75px;
    opacity: ${({image}) => image ? 1 : .5};
    
    & > input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        
        cursor: pointer;
    }
`;

const Title = styled.div`
    font-size: 125%;
`;

const AdminLink = styled(props => <Link to='/admin' {...props}><FaWrench/></Link> )`
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 28px;
    background: rgba(255, 0, 0, .25);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
`;

const VIEWER_QUERY = gql`
{
    viewer {
        firstName
        lastName
        avatar {
            url
        }
        admin
    }
}
`;

function setUserImage() {
    console.error('not implemented');
}

const Header = () => {
    const {data:{viewer={}}={}} = useQuery(VIEWER_QUERY);

    return (
        <HeaderWrapper>
            <Avatar image={viewer.avatar && viewer.avatar.url}>
                {!(viewer.avatar) && <FaUser/>}
                <input type='file' onChange={e => uploadPhoto(e.target, 'user/image', setUserImage)} accept="image/*" value=""/>
            </Avatar>
            <Title>{viewer.firstName}'s Urban Agriscape</Title>
            {viewer.admin && <AdminLink/>}
        </HeaderWrapper>
    );
};

const ListHeadingWrapper = styled.div`
    background-color: #f0f0f0;
    padding: 12px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const ListHeadingLabel = styled.div`
    flex: 1;
    text-align: center;
    font-weight: bold;
    font-size: 120%;
    color: ${({theme:{highlight:{color}}}) => color};
`;

const ListHeading = ({children, icon, onClick}) => {
    return (
        <ListHeadingWrapper onClick={onClick}>
            {icon && <IconWrapper>{createElement(icon, {color: 'transparent'})}</IconWrapper>}
            <ListHeadingLabel>
                {children}
            </ListHeadingLabel>
            {icon && <IconWrapper>{createElement(icon)}</IconWrapper>}
        </ListHeadingWrapper>
    );
};



// task categories
const CATEGORIES = {
    planted: new RegExp(/.*(seeding|transplant).*/i),
    harvested: new RegExp(/.*(harvest).*/i)
};

const KG_LB = 0.453592;
const OZ_LB = 16;

class WeightInput extends Component {
    state = {
        lbs: '',
        ozs: '',
        grams: '',
        value: 0,
        units: window.localStorage['weight_units'] || 'imperial'
    };

    interpret(e) {
        // strip invalid chars
        let value = (typeof(e) === 'string' ? e : e.target.value).replace(/[^.0-9]+/g, '').split('.').slice(0, 2).join('.');

        if (value.length > 0 && value[0] === '.')
            value = `0${value}`;

        return {number: !value ? 0 : Number(value.replace(/\.$/, '')), string: value};
    }

    lbChange = e => {
        const {number: lbs, string} = this.interpret(e);

        const value = (lbs + this.interpret(this.state.ozs).number / OZ_LB) * KG_LB * 1000;

        this.setState({lbs: string, value});

        this.props.onChange(value);
    };

    ozChange = e => {
        const {number: ozs, string} = this.interpret(e);

        const value = (this.interpret(this.state.lbs).number + ozs / OZ_LB) * KG_LB * 1000;

        this.setState({ozs: string, value});

        this.props.onChange(value);
    };

    gChange = e => {
        const {number: value, string} = this.interpret(e);

        this.setState({grams: string, value});

        this.props.onChange(value);
    };

    render() {
        const {className} = this.props;
        const {units} = this.state;

        return (
            <div className={className}>
                {units === 'imperial' ? this.renderImperial() : this.renderMetric()}
                <FaToggleOn className={units === 'imperial' ? 'rotate' : ''} size={24} onClick={this.toggleUnits}/>
            </div>
        )
    }

    renderImperial() {
        const {lbs, ozs} = this.state;

        return (
            <div>
                <label><Input onChange={this.lbChange} value={lbs}/>lbs</label>
                <label><Input onChange={this.ozChange} value={ozs}/>oz</label>
            </div>
        )
    }

    renderMetric() {
        const {grams} = this.state;

        return (
            <label><Input onChange={this.gChange} value={grams}/>g</label>
        )
    }

    toggleUnits = () => {
        const units = this.state.units === 'imperial' ? 'metric' : 'imperial';

        const {value} = this.state;

        if (units === 'imperial') {
            const fraction = (value / 1000) / KG_LB;
            const lbs = Math.floor(fraction);
            const ozs = ((fraction - lbs) * OZ_LB).toFixed(3);

            this.setState({units, lbs: lbs.toFixed(0), ozs: ozs.replace(/(\.\d)0+$/, '$1').replace(/\.0$/, '')});
        }
        else {
            this.setState({units, grams: value.toString()});
        }

        window.localStorage.setItem('weight_units', units);
    }
}

WeightInput = styled(WeightInput)`
    display: flex;
    align-items: center;
    
    div {
        flex: 1;
        display: flex;
        justify-content: space-around;
    }
    
    input {
        margin-left: .5em;
        max-width: 3em;
        text-align: right;
    }
    
    svg {
        margin-left: 1em;
        cursor: pointer;
        color: #008
    }
    
    svg.rotate {
        transform: rotate(180deg);
    }
`;

const QuantityInputLine = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
`;

class Tasks extends Component {
    state = {
        showAllTasks: false,
        harvest: null,
        harvestQuantity: 0
    };

    getTasks() {
        const {showAllTasks} = this.state;
        const {tasks=[]} = this.props;

        if (showAllTasks)
            return tasks;

        const completed = {};

        const generateKey = task => `${task.gid}|${task.name}`;

        tasks.filter(({complete}) => complete)
            .forEach(task => {
                const key = generateKey(task);

                if (!completed[key])
                    completed[key] = [];

                Object.keys(CATEGORIES).forEach(flag => {
                    const regexp = CATEGORIES[flag];

                    if (regexp.test(task.event))
                        completed[key].push(regexp);
                });
            });

        // only show tasks that haven't been completed
        return tasks.filter(task => {
            const regexps = completed[generateKey(task)];

            if (!regexps)
                return true;

            return regexps.filter(regexp =>
                regexp.test(task.event)).length === 0
        });
    }

    toggleShowAll = e => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ showAllTasks: !this.state.showAllTasks});
    };

    toggleTaskComplete(task, e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {saveTask} = this.props;

        saveTask(Object.assign({}, task, {complete: !task.complete}));
    }

    harvestDialog(task, e) {
        this.setState({harvest:task});
    }

    setHarvestQuantity = e => {
        const harvestQuantity = typeof(e) === 'number' ? e : Number(e.target.value);

        this.setState({harvestQuantity});
    };

    saveHarvestQuantity = () => {
        const {harvest, harvestQuantity} = this.state;

        this.setState({harvest:null, harvestQuantity:0});

        if (!harvestQuantity)
            return;

        const {saveHarvest} = this.props;

        saveHarvest(Object.assign({}, harvest), harvestQuantity);
    };

    harvestComplete = () => {
        this.saveHarvestQuantity();

        const {harvest} = this.state;

        this.toggleTaskComplete(harvest);
    };

    render() {
        const {theme} = this.props;
        const tasks = this.getTasks();

        const harvestTasks = [], otherTasks = [];

        tasks.forEach(task => {
            const {event} = task;

            if (event.match(/.*harvest*/i))
                harvestTasks.push(task);
            else
                otherTasks.push(task);
        });

        const harvestList = harvestTasks.map((task, i) => (
            <ListItem
                key={i}
                icon={FaCheck}
                iconColor={task.complete ? theme.highlight.color : theme.disabled.color}
                onClick={e => this.harvestDialog(task, e)}
                highlight={task.overdue}
            >
                {task.name} - {task.event} - {task.garden}
            </ListItem>
        ));

        const taskList = otherTasks.map((task, i) => (
            <ListItem
                key={i}
                icon={FaCheck}
                iconColor={task.complete ? theme.highlight.color : theme.disabled.color}
                onClick={e => this.toggleTaskComplete(task, e)}
                highlight={task.overdue}
            >
                {task.name} - {task.event} - {task.garden}
            </ListItem>
        ));

        const {showAllTasks, harvest, harvestQuantity} = this.state;

        const input = harvest && harvest.quantity_type === 0 ? (
                <WeightInput onChange={this.setHarvestQuantity} defaultValue={harvestQuantity}/>
            ) : (
                <Input type="number" defaultValue={harvestQuantity} min={0} onChange={this.setHarvestQuantity} style={{marginLeft:'.5em', maxWidth:'4em', textAlign: 'right'}}/>
        );

        return (
            <div>
                {harvestTasks.length > 0 && (
                    <div>
                        <ListHeading>Harvest</ListHeading>
                        {harvestList}
                        {harvest && (
                            <Dialog>
                                <h3>Harvest {harvest.name}</h3>
                                <QuantityInputLine>Quantity: {input}</QuantityInputLine>
                                <Buttons>
                                    <Button onClick={() => this.saveHarvestQuantity()}>OK</Button>
                                    <Button type='warn' onClick={() => this.harvestComplete()}>Harvest {harvest.complete ? 'In' : 'C'}omplete</Button>
                                </Buttons>
                            </Dialog>
                        )}
                    </div>
                )}
                <ListHeading onClick={this.toggleShowAll}>
                    {showAllTasks ? 'All' : 'Current'} Tasks
                </ListHeading>
                {taskList}
            </div>
        );
    }
}

Tasks = withTheme(Tasks);

const Gardens = ({gardens=[]}) => {
    const history = useHistory();

    const gardenList = gardens.map(garden => (
        <ListItem
            key={garden.id}
            icon={FaChevronRight}
            onClick={() => history.push(`/gardens/${garden.id}`)}
        >
            {garden.name}
        </ListItem>
    ));

    return (
        <div>
            <ListHeading icon={FaPlus} onClick={e => history.push(`/gardens/new`)}>
                Planting Zones
            </ListHeading>
            {gardenList}
        </div>
    );
};

const GARDEN_QUERY = gql`
{
  viewer {
    gardens {
      nodes {
        id
        name
        image {
          url
        }
        gardenPlants {
          nodes {
            plant {
              commonName
              image {
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

const Wrapper = () => {
    const {data={}} = useQuery(GARDEN_QUERY);

    const {viewer:{gardens:{nodes:gardens}}={gardens:{nodes:[]}}} = data;

    return (
        <Page>
            <Header/>
            <ScrollView>
                <Tasks/>
                <Gardens gardens={gardens}/>
            </ScrollView>
        </Page>
    );
};

export default Wrapper;
