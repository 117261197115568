import styled, {keyframes} from 'styled-components';

const appear = keyframes`
    0% {
        opacity: 0;
    }
    
    10% {
        opacity: 1;
    }
    
    90% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
    }
`;

const Error = styled.div`
    color: ${({theme:{warn:{color}}}) => color};
    background-color: ${({theme:{warn:{background}}}) => background};
    font-weight: bold;
    padding: .5em 1em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 24px;
    
    animation: ${appear} 3s;
    animation-fill-mode: forwards;
`;

export default Error;