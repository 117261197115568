import React, {Component} from 'react';
import styled from 'styled-components';
import {FaChevronLeft,FaCamera,FaComment,FaPhone,FaEnvelope,FaUserSecret,FaUserPlus,FaKey} from 'react-icons/fa';

import api from '../../api';
import AutocompleteUser from './AutocompleteUser';
import Header from '../Header';
import Button from '../Button';

function saveUser(user) {
    return api('admin/user/save', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => response.json());
}

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const UserPhoto = styled.div.attrs({
    children: ({image}) => !image ? <FaCamera/> : null
})`
    background-color: #ddd;
    background-image: ${({image}) => `url(${image})`};
    background-size: cover;
    background-position: center center;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 30px;
    margin-right: 20px;
`;

const Link = Button.withComponent('a');

function impersonate(id) {
    window.sessionStorage['impersonate'] = id;
    window.location = '/';
}

const UserWrapper = styled.div`
    padding: 20px;
    font-size: 150%;
    
    align-items: center;
    
    section {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    
    > div {
        display: flex;
    }
    
    ${Link}, ${Button} {
        margin-right: 20px;
    }
`;

class User extends Component {
    constructor(props) {
        super(props);

        const {user} = props;

        this.state = {user};
    }

    update(property) {
        saveUser(Object.assign({}, this.state.user, property))
            .then(user => {
                this.setState({user});
            });
    };

    render() {
        const {user} = this.state;
        return (
            <UserWrapper>
                <section>
                    <UserPhoto image={user.image}/>
                    <div>
                        <div>{user.lastName} {user.firstName}</div>
                        <div>{user.email}</div>
                        {user.phone && <div>{user.phone}</div>}
                    </div>
                </section>
                <div>
                    {false && <Button title="Message User" to={`/ask-a-pro/${user.id}`}><FaComment/></Button>}
                    {user.phone &&
                    <Link title="Call User" href={`tel:${user.phone.replace(/\D+/g, '')}`}><FaPhone/></Link>}
                    <Link title="Email User" href={`mailto:${user.email}`}><FaEnvelope/></Link>
                    <Button title="Impersonate User" onClick={() => impersonate(user.id)}><FaUserSecret/></Button>
                    <Button title="Toggle Master Account" inactive={!user.master}
                            onClick={() => this.update({master: !user.master})}><FaUserPlus/></Button>
                    <Button title="Toggle Admin Account" inactive={!user.admin}
                            onClick={() => this.update({admin: !user.admin})}><FaKey/></Button>
                </div>
            </UserWrapper>
        )
    }
}

class Users extends Component {
    state = {
        user: null
    };

    onSelectUser = user => this.setState({user});

    render() {
        const {history:{goBack}} = this.props;
        const {user} = this.state;

        return (
            <Wrapper>
                <Header
                    renderLeftIcon={<FaChevronLeft/>}
                    onLeftClick={goBack}
                />
                <AutocompleteUser placeholder="Name or Email" onSelect={this.onSelectUser}/>
                {user && <User key={user.id} user={user}/>}
            </Wrapper>
        );
    }
}

export default Users;