import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import styled from 'styled-components';
import {FaChevronLeft, FaCamera} from 'react-icons/fa';

import Header from '../../component/Header';
import Heading from '../../component/Heading';
import Api from '../Api';
import ScrollView from '../ScrollView';
import Input from '../Input';
import TextArea from '../TextArea';
import Select from '../Select';
import Button from '../Button';
import {uploadPhoto, savePlant} from '../../api';
import AutocompletePlant from '../AutocompletePlant';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    flex-grow: 1;
    display: flex;
`;

const List = styled(({plants, onSelect, ...rest}) => (
    <div {...rest}>
        <Heading>Edit Plants</Heading>
        <AutocompletePlant
            placeholder="Search Plants..."
            onSelect={onSelect}
        />
    </div>
))`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    ${Button} {
        margin-top: 20px;
        align-self: center;
    }
`;

const Label = styled.label`
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    color: ${({theme:{subtle:{color}}}) => color};
    
    span {
        display: block;
        min-width: 120px;
        text-align: right;
    }
    
    ${Input}, ${TextArea}, select {
        margin-left: 5px;
        flex-grow: 1;
    }
    
    @media (max-width: 450px) {
        flex-direction: column;
        text-align: left;
        align-items: stretch;
        
        span {
            text-align: left;
        }
        
        ${Input}, ${TextArea} {
            margin-left: 0;
        }
    }
`;

const FIELDS = [
    'Common_Name',
    'Scientific_Name',
    'Description',
    'Category',
    'Rotation',
    'Wikipedia_Title',
    'Classification',
    'Quantity_Type',
    'Notes',
];

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    
    @media (max-width: 450px) {
        flex-direction: column;
    }
`;

const Photo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 36px;
    width: 400px;
    height: 300px;
    background-color: #ddd;
    background-image: ${({image}) => `url(${image})`};
    background-position: center center;
    background-size: cover;
    margin: 0 20px 20px;
    
    @media (max-width: 450px) {
        width: 100%;
        height: 0;
        padding-top: 37.5%;
        padding-bottom: 37.5%;
        margin: 0 0 20px;
    }

    
    & > input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
        
        cursor: pointer;
    }
`;

class Editor extends Component {
    state = {
        upload: null
    };

    setImage = ({url}) => {
        if (url)
            this.setState({
                upload: url
            });
    };

    uploadPhoto = e => {
        const {plant: {Agri_Item_ID: id}, refresh} = this.props;

        uploadPhoto(e.target, `admin/item/image?id=${id}`, this.setImage)
            .then(refresh)
    };

    save = e => {
        e.preventDefault();

        const {plant: {Agri_Item_ID: id}, onSave} = this.props;

        const form = e.target;

        const data = {
            Agri_Item_ID: id
        };

        FIELDS.forEach(field => data[field] = form[field].value);

        savePlant(data).then(({response}) => {
            if (response === 'OK') {
                if (typeof onSave === 'function')
                    onSave();
            }
        });
    };

    render() {
        const {plant, plant: {Image}} = this.props;
        const {upload} = this.state;

        return (
            <ScrollView>
                <Row>
                    <Photo image={upload || Image}>
                        {!!Image || <FaCamera/>}
                        <input type='file' onChange={this.uploadPhoto}
                               accept="image/*" value=""/>
                    </Photo>
                    <Form onSubmit={this.save}>
                        {FIELDS.map(field => (
                            <Label key={field}>
                                {field !== 'Notes' && <span>{field.replace(/_/, ' ')}</span>}
                                {renderInput(plant, field)}
                            </Label>
                        ))}
                        <Button>Save</Button>
                    </Form>
                </Row>
            </ScrollView>
        );
    }
}

function renderInput(plant, field) {
    switch (field) {
        case 'Quantity_Type':
            return (
                <Select name={field} defaultValue={plant[field]}>
                    <option value={0}>Weight</option>
                    <option value={1}>Count</option>
                </Select>
            );
        case 'Notes':
            return (
                <TextArea name={field} defaultValue={plant[field]} rows={5} placeholder={field}/>
            );
        default:
            return (
                <Input name={field} defaultValue={plant[field]}/>
            );
    }
}

const EditPlant = ({history:{goBack, push}, match}) => (
    <Wrapper>
        <Header
            renderLeftIcon={<FaChevronLeft/>}
            onLeftClick={goBack}
        />
        <Api path='admin/item/list' render={({data:plants, refresh}) => {
            return (
                <Container>
                    <Route exact path={match.url} render={() => (
                        <List plants={plants} onSelect={selection => push(`${match.url}/${selection.Agri_Item_ID || selection.itemId}`)}/>
                    )}/>
                    <Route path={`${match.url}/:id`} render={({match:{params:{id}}}) => <Editor plant={plants.find(plant => plant.Agri_Item_ID===id)} refresh={refresh} onSave={goBack}/>}/>
                </Container>
            );
        }}/>
    </Wrapper>
);

export default EditPlant;