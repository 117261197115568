import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
`;

const Name = styled.div`
    flex-grow: 1;
`;

const Plant = styled.div.attrs({
    children: ({plant}) => (
        <Row>
            <Name>{plant.name}</Name>
        </Row>
    )
})`
    font-size: 25px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    
    &:hover {
        background-color: ${({theme:{hover:{background}}}) => background};        
    }
`;

export default Plant;