import React, {useState} from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import {FaTimesCircle,FaCamera} from 'react-icons/fa';
import {useQuery} from "@apollo/react-hooks";

const AutocompleteWrapper = styled.div`
    padding: 5px;
`;

const BACKGROUND = 'rgba(240, 240, 240, .95)';

const InputWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    background-color: ${BACKGROUND};
    display: flex;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: ${({children}) => children[2] ? '0' : '20px'};
    border-bottom-right-radius: ${({children}) => children[2] ? '0' : '20px'};
    border-bottom: ${({children}) => children[2] ? '1px solid rgba(0,0,0,0.1)' : '1px solid transparent'};
    height: 40px;
    padding: 0 20px;
    align-items: center;
    
    input {
        flex: 1;
        border: none;
        background: transparent;
        font-size: 16px;
    }
`;

const AutoCompleteOptionsWrapper = styled.div`
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    background-color: ${BACKGROUND};
    max-height: 75vh;
    overflow-y: auto;
    padding: 5px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const AutoCompleteOption = styled.div`
    display: flex;
    padding: 1px 20px;
    color: ${({onClick}) => onClick ? 'inherit' : '#666'};
    cursor: ${({onClick}) => onClick ? 'pointer' : 'default'};
    
    &:hover {
        background-color: ${({theme:{hover:{background}}}) => background};        
    }
`;

const PlantPhoto = styled.div.attrs({
    children: ({image}) => !image ? <FaCamera/> : null
})`
    background-color: #ddd;
    background-image: ${({image}) => `url(${image})`};
    background-size: cover;
    background-position: center center;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 30px;
`;

const PlantName = styled.div`
    margin-left: 10px;
    padding: 10px 0;
    font-size: 25px;
`;

const AutoCompleteOptions = ({search, filter, onSelect, plants}) => {
    const regexp = new RegExp(search.toLowerCase().trim().replace(/\s+/g, '.*'));
    const results = plants
        .filter(plant => {
            if (typeof filter === 'function' && !filter(plant))
                return false;

            const name = plant.commonName; // `${plant.commonName} ${plant.scientificName}`

            return regexp.test(name.toLowerCase());
        });

    const options = results.length > 0 ?
        results.map(plant => (
            <AutoCompleteOption key={plant.id} onClick={() => onSelect(plant)}>
                <PlantPhoto image={plant.image && plant.image.url}/>
                <PlantName>{plant.commonName}</PlantName>
            </AutoCompleteOption>
        )) : (
            <AutoCompleteOption>
                <PlantName>&mdash; No Results &mdash;</PlantName>
            </AutoCompleteOption>
        );

    return (
        <AutoCompleteOptionsWrapper>
            {options}
        </AutoCompleteOptionsWrapper>
    );
};

const Clear = styled(FaTimesCircle)`
    font-size: 26px;
    margin-right: -13px;
    cursor: pointer;
    color: ${({theme:{subtle:{color}}}) => color};
`;

const AutoComplete = ({onSelect:onSelectCallback, placeholder, filter, plants}) => {
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState(false);

    const onChange = e => {
        const input = e.target;

        setSearch(input.value);
        setSelected(false);
    };

    const onSelect = plant => {
        if (typeof onSelectCallback === 'function')
            onSelectCallback(plant, () => clear());

        setSearch(plant.name);
        setSelected(true);
    };

    const clear = () => {
        setSearch('');
    };

    return (
        <AutocompleteWrapper>
            <InputWrapper>
                <input placeholder={placeholder} onChange={onChange} value={search}/>
                <Clear onClick={clear}/>
                {!selected && search.trim().length > 0 &&
                    <AutoCompleteOptions
                        search={search}
                        onSelect={onSelect}
                        filter={filter}
                        plants={plants}
                    />
                }
            </InputWrapper>
        </AutocompleteWrapper>
    );
};

const PLANTS_QUERY = gql`
{
  plants {
    nodes {
        id
        commonName
        scientificName
        wikipediaTitle
        image {
            url
        }
    }
  }
}
`;

export default props => {
    const {data={}} = useQuery(PLANTS_QUERY);

    const {plants:{nodes:plants}={nodes:[]}} = data;

    return (
        <AutoComplete {...props} plants={plants}/>
    );
};
