import React from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {Swipe} from 'react-swipe-component';

import OverlayBase from '../component/Overlay';

const TIMEOUT = 150;

const Overlay = styled(OverlayBase)`
    opacity: ${({theme:{overlay:{opacity}}}) => opacity};
    
    transition: opacity ${TIMEOUT}ms ease-in;

    &.overlay-enter, &.overlay-exit.overlay-exit-active {
        opacity: 0.01;
    }
    
    &.overlay-enter.overlay-enter-active, &.overlay-exit {
        opacity: ${({theme:{overlay:{opacity}}}) => opacity};
    }
`;

const Drawer = ({children, show, onClose, className, style, side="right"}) => {
    const right = side !== 'left';

    const overlayParams = {
        key: 'overlay',
        onClick: onClose,
        mouseSwipe: true,
        [right ? 'onSwipedRight' : 'onSwipedLeft']: onClose
    };

    return (
        <TransitionGroup>
            {show && <CSSTransition classNames="overlay" timeout={{enter:TIMEOUT, exit:TIMEOUT}}>
                <Overlay as={Swipe} {...overlayParams}/>
            </CSSTransition>}
            {show && <CSSTransition classNames="drawer" timeout={{enter:TIMEOUT, exit:TIMEOUT}}>
                <div key={side} className={[side].concat(className).join(' ')}>
                    {children}
                </div>
            </CSSTransition>}
        </TransitionGroup>
    );
};

export default styled(Drawer)`
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 10px 5px;
    z-index: 32767;
    box-shadow: 0 0 4px rgba(0,0,0,.5);
    overflow-y: auto;

    display: flex;
    
    form {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    
    h3 {
        color: ${({theme:{highlight:{color}}}) => color};
        margin: 0 0 10px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    label {
        color: ${({theme:{subtle:{color}}}) => color};
        font-size: 14px;
        flex-shrink: 0;
    }
    
    input[type='text'],
    input[type='number'],
    input[type='date'],
    select {
        box-sizing: border-box;
        height: 28px;
        font-size: 18px;
        border: none;
        border-radius: 5px;
        padding: 3px 5px;
        margin: 2px -5px 10px;
        flex-shrink: 0;
    }

    button {
        margin-top: 20px;
    }
        
    input[type='text'],
    input[type='number'],
    input[type='date'],
    select {
        color: ${({theme:{input:{color}}}) => color};
        background-color: ${({theme:{input:{background}}}) => background};
    }
    
    transition: transform ${TIMEOUT}ms ease-in;
    
    &.right {
        right: 0;
    }
    
    &.drawer-exit {
        transform: translateX(0%);
    }
    
    &.right.drawer-enter, &.right.drawer-exit-active {
        transform: translateX(100%);
    }

    &.left.drawer-enter, &.left.drawer-exit-active {
        transform: translateX(-100%);
    }
    
    &.drawer-enter.drawer-enter-active {
        transform: translateX(0%);
    }
`;