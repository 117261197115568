import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
    display: flex;
    flex-shrink: 0;
    padding: 0 5px;
    align-items: center;
    border-bottom: 1px solid #eee;
    background-color: ${({theme:{heading:{background}}}) => background};
    color: ${({theme:{heading:{color}}}) => color};
`;

const Icon = styled.div`
    width: 40px;
    font-size: 24px;
    line-height: 24px;
    cursor: ${({children}) => children ? 'pointer' : 'default'};
    color: ${({theme:{heading:{color}}}) => color};
`;

const LeftIcon = styled(Icon)`
    text-align: left;
`;

const Title = styled.div`
    flex: 1;
    text-align: center;
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
    text-transform: uppercase;
`;

const RightIcon = styled(Icon)`
    text-align: right;
`;

export default ({renderLeftIcon, onLeftClick, renderRightIcon, onRightClick}) => (
    <Header>
        <LeftIcon onClick={() => onLeftClick()}>
            {typeof renderLeftIcon === 'function' ? renderLeftIcon() : renderLeftIcon}
        </LeftIcon>
        <Title>
            Agriscaping
        </Title>
        <RightIcon onClick={() => onRightClick()}>
            {typeof renderRightIcon === 'function' ? renderRightIcon() : renderRightIcon}
        </RightIcon>
    </Header>
);