import React from 'react';
import styled from 'styled-components';
import {FaChevronRight} from 'react-icons/fa';
import gql from 'graphql-tag';
import {useHistory} from 'react-router-dom';

import Header from '../../component/Header';
import Heading from '../../component/Heading';
import ScrollView from '../../component/ScrollView';
import ListItem from '../../component/ListItem';
import Spinner from '../../component/Spinner';

import {useQuery} from "@apollo/react-hooks";

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const PRO_TOPICS_QUERY = gql`
{
    allBadgeTypes(condition:{askAPro:true}) {
      nodes {
        id
        name
      }
    }
}
`;

const AskAPro = styled(props => {
    const {loading, data} = useQuery(PRO_TOPICS_QUERY);

    if (loading)
        return <div {...props}/>;

    const {
        allBadgeTypes: {nodes: askAProTopics}
    } = data;

    return (
        <div {...props}>
            <Heading>Ask A Pro</Heading>
            <select>
                <option>What can our Certified Pros help you with today?</option>
                {askAProTopics.map(({id, name}) => <option key={id} value={id}>{name}</option>)}
            </select>
        </div>
    );
})`
    margin-bottom: 20px;
    text-align: center;
    
    select {
        margin: 5px;
        background: transparent;
        padding: 5px;
        font-size: 16px;
    }
`;

const Conversations = ({conversations, user={}}) => {
    const history = useHistory();

    return (
        <Wrapper>
            <Header/>
            {!user.master && (
                <AskAPro/>
            )}
            {conversations.length ? <Heading>Conversations</Heading> : ''}
            <ScrollView>
                {conversations.map(conversation => (
                    <ListItem
                        key={conversation.account.id}
                        highlight={conversation.unread}
                        icon={FaChevronRight}
                        onClick={() => history.push(`/messages/${conversation.account.id}`)}
                    >
                        {conversation.account.firstName} {conversation.account.lastName}
                    </ListItem>
                ))}
            </ScrollView>
        </Wrapper>
    );
};

const CONVERSATIONS_QUERY = gql`
{
    viewer {
        messageCounts {
            nodes {
                account {
                    id
                    firstName
                    lastName
                }
                total
                unread
                mostRecent
            }
        }
    }    
}
`;

export default props => {
    const {loading, data, error} = useQuery(CONVERSATIONS_QUERY, {
        fetchPolicy: 'cache-and-network'
    });

    if (error)
        console.error(error);

    if (loading || error)
        return <Spinner/>;

    const {
        viewer: {messageCounts: {nodes: conversations}}
    } = data;

    return <Conversations {...props} conversations={conversations}/>;
}