import styled from 'styled-components';

const Heading = styled.div`
    text-align: center;
    padding: 10px 0;
    color: #333;
    font-size: 28px;
    font-weight: bold;
`;

export default Heading;