import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 32766;
    opacity: ${({theme:{overlay:{opacity}}}) => opacity};
`;

export default Overlay;