import React, {Component} from 'react';
import styled from 'styled-components';
import {FaTimesCircle, FaCamera} from 'react-icons/fa';

import Error from '../Error';
import api from '../../api';

function fetchUsers(search) {
    return api(`admin/user/autocomplete?q=${encodeURIComponent(search)}`, {
        credentials: 'include',
    })
        .then(response => response.json());
}

const AutocompleteWrapper = styled.div`
    padding: 5px;
`;

const BACKGROUND = 'rgba(240, 240, 240, .95)';

const InputWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    background-color: ${BACKGROUND};
    display: flex;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: ${({children}) => children[2] ? '0' : '20px'};
    border-bottom-right-radius: ${({children}) => children[2] ? '0' : '20px'};
    border-bottom: ${({children}) => children[2] ? '1px solid rgba(0,0,0,0.1)' : '1px solid transparent'};
    height: 40px;
    padding: 0 20px;
    align-items: center;
    
    input {
        flex: 1;
        border: none;
        background: transparent;
        font-size: 16px;
    }
`;

const AutoCompleteOptionsWrapper = styled.div`
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    background-color: ${BACKGROUND};
    max-height: 75vh;
    overflow-y: auto;
    padding: 5px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const AutoCompleteOption = styled.div`
    display: flex;
    padding: 1px 20px;
    color: ${({onClick}) => onClick ? 'inherit' : '#666'};
    cursor: ${({onClick}) => onClick ? 'pointer' : 'default'};
    
    &:hover {
        background-color: ${({theme:{hover:{background}}}) => background};        
    }
`;

const UserPhoto = styled.div.attrs({
    children: ({image}) => !image ? <FaCamera/> : null
})`
    background-color: #ddd;
    background-image: ${({image}) => `url(${image})`};
    background-size: cover;
    background-position: center center;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 30px;
`;

const UserName = styled.div`
    margin-left: 10px;
    padding: 10px 0;
    font-size: 25px;
`;

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+(?:\.[^\s@]+)+$/;

const AutoCompleteOptions = ({search, onSelect, users, checkInfusionsoft}) => {
    const email = EMAIL_REGEX.test(search) && search;

    const options = !users ? (
        <AutoCompleteOption>
            <UserName>Searching...</UserName>
        </AutoCompleteOption>
    ) : users.length > 0 ?
        users.map(user => (
            <AutoCompleteOption key={user.id} onClick={() => onSelect(user)}>
                <UserPhoto image={user.image}/>
                <UserName>{user.lastName}, {user.firstName} ({user.email})</UserName>
            </AutoCompleteOption>
        )) : (
            <AutoCompleteOption onClick={() => email && checkInfusionsoft(email)}>
                <UserName>{email ? `Check Infusionsoft for ${email}` : <span>&mdash; No Results &mdash;</span>}</UserName>
            </AutoCompleteOption>
        );

    return (
        <AutoCompleteOptionsWrapper>
            {options}
        </AutoCompleteOptionsWrapper>
    );
};

const Clear = styled(FaTimesCircle)`
    font-size: 26px;
    margin-right: -13px;
    cursor: pointer;
    color: ${({theme:{subtle:{color}}}) => color};
`;

class AutoComplete extends Component {
    state = {
        search: '',
        selected: false,
        matches: null,
        error: null,
    };

    onChange = e => {
        const input = e.target;

        const search = input.value;

        this.setState({search, selected: false});

        if (search.length > 0) {
            fetchUsers(search)
                .then(matches => this.setState({matches}));
        }
    };

    onSelect = user => {
        const {onSelect} = this.props;

        if (typeof onSelect === 'function')
            onSelect(user, () => this.clear());

        this.setState({search: '', selected: true});
    };

    checkInfusionsoft = email => {
        console.log(`check infusinosoft for ${email}`);

        api(`admin/user/check-infusionsoft?email=${encodeURIComponent(email)}`, {
            credentials: 'include',
        })
            .then(response => response.json())
            .then(({error, ...user}) => {
                if (error)
                    this.setState({error}, () => setTimeout(() => this.setState({error:null}), 3000));
                else
                    this.onSelect(user);
            });
    };

    clear = () => {
        this.setState({search: ''});
    };

    render() {
        const {search, selected, matches, error} = this.state;
        const {placeholder} = this.props;

        return (
            <AutocompleteWrapper>
                <InputWrapper>
                    <input placeholder={placeholder} onChange={this.onChange} value={search}/>
                    <Clear onClick={this.clear}/>
                    {!selected && search.trim().length > 0 &&
                        <AutoCompleteOptions
                            search={search}
                            users={matches}
                            onSelect={this.onSelect}
                            checkInfusionsoft={this.checkInfusionsoft}
                        />
                    }
                </InputWrapper>
                {error && <Error>{error}</Error>}
            </AutocompleteWrapper>
        )
    }
}

export default AutoComplete;