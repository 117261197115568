// https://github.com/react-ga/react-ga/issues/122
import React, {Component} from 'react';
import {Route} from 'react-router-dom';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-115905016-1');

class Analytics extends Component {
    componentDidMount() {
        this.sendPageChange(this.props.location.pathname, this.props.location.search)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname
            || this.props.location.search !== prevProps.location.search) {
            this.sendPageChange(this.props.location.pathname, this.props.location.search)
        }
    }

    sendPageChange(pathname, search = '') {
        const page = pathname + search;
        ReactGA.set({page});
        ReactGA.pageview(page);
    }

    render() {
        return null;
    }
}

const AnalyticsTracker = () => {
    return <Route component={Analytics}/>
};

export default AnalyticsTracker;