import styled from 'styled-components';

import Button from './Button';

function oneButton(children) {
    return !(children instanceof Array && children.length > 1);
}

const Buttons = styled.div`
    display: flex;
    justify-content: ${({center, children}) => center || oneButton(children) ? 'center' : 'space-between'};
    
    ${Button} {
        margin: 10px;
        flex-grow: ${({children}) => oneButton(children) ? 0 : 1};
    }
`;

export default Buttons;