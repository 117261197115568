function api(endpoint, options={}) {
    if (endpoint !== 'login')
        return;

    const jwt = window.localStorage.getItem('jwt');
    const impersonateId = window.sessionStorage.getItem('impersonate');

    const authorization = jwt ? {Authorization: `Bearer ${jwt}`} : {};

    const impersonate = impersonateId ? {'X-Impersonate': impersonateId} : {};

    const headers = Object.assign(authorization, impersonate, options.headers);

    return fetch(`/${endpoint}`, Object.assign(options, {
        headers
    }));
}

export function uploadPhoto(input, path, setImage) {
    const file = input.files && input.files[0];

    if (!(file && file.type.match(/^image\//)))
        return;

    const reader = new FileReader();
    reader.onload = ({target:{result:dataUrl}}) => {
        const image = new Image();
        image.addEventListener('load', () => {
            setImage({
                url: dataUrl,
                width: image.naturalWidth,
                height: image.naturalHeight
            });
        });
        image.src = dataUrl;
    };
    reader.readAsDataURL(file);

    const data = new FormData();
    data.append('file', file);

    return api(path, {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: data
    }).then(response => response.json());
}

export function savePlant(data) {
    return api('admin/item/save', {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => response.json());
}

export function saveUserPlant(plant) {
    return api('garden/save-plant', {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: JSON.stringify(plant)
    }).then(response => response.json());
}

export default api;