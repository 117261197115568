import React,{Component} from 'react';
import styled from 'styled-components';
import {FaChevronLeft,FaPaperPlane,FaCamera,FaUserSecret} from 'react-icons/fa';
import gql from 'graphql-tag';

import Header from '../../component/Header';
import Heading from '../../component/Heading';
import Spinner from '../../component/Spinner';
import ScrollView from "../../component/ScrollView";
import Input from "../../component/Input";
import {uploadPhoto} from "../../api";
import {useQuery} from "@apollo/react-hooks";

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Form = styled.form`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    
    input {
        flex: 1;
        margin: 5px 10px;
    }
`;

const MessageBody = styled.div`
    padding: 15px 15px 5px;
    display: inline-block;
    
    img {
        width: 100%;
        height: auto;
        cursor: pointer;
    }
`;

const MessageDate = styled.div`
    padding-top: 5px;
    font-size: 75%;
    color: rgba(0, 0, 0, .5);
`;

const Message = styled(({message, onLoad, ...rest}) => {
    const content = /<img src="/.test(message.message) ? (
        <img
            src={message.message.replace(/^<img[^>]+src="([^"]+)".*$/, '$1')}
            alt=""
            onLoad={onLoad}
            onClick={e => window.open(e.target.src, 'photo-full-view')}
        />
    ) : (
        message.message
    );

    const sent = new Date(message.createdAt);

    return (
        <div {...rest}>
            <MessageBody>
                {content}
                <MessageDate>{sent.toDateString()} {sent.toLocaleTimeString()}</MessageDate>
            </MessageBody>
        </div>
    );
})`
    margin: 0 10px 10px;
`;


const AfterLastMessage = styled.div`
`;

const SendButton = styled(({...rest}) => (
    <div {...rest}>
        <FaPaperPlane/>
    </div>
))`
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    background: ${({theme: {ok: {background}}, disabled}) => disabled ? '#ccc' : background};
    color: #fff;
    width: 1em;
    height: 1em;
    padding: .5em;
    border-radius: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CameraButton = styled(({id, onSend, ...rest}) => (
    <div {...rest}>
        <FaCamera/>
        <input type='file' onChange={e => uploadPhoto(e.target, `chat/sendImage?recipient=${id}`, onSend)} accept="image/*" value=""/>
    </div>
))`
    position: relative;
    width: 1em;
    height: 1em;
    overflow: hidden;
    cursor: pointer;

    & > input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        opacity: 0;
    }
`;

const Sent = styled(Message)`
    text-align: right;
    padding-left: 15vw;

    ${MessageBody} {
        background-color: #e8ffe8;
    }
`;

const Received = styled(Message)`
    padding-right: 15vw;
    
    ${MessageBody} {
        background-color: #fff0db;
    }
`;

const HeadingContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        margin-left: 16px;
        cursor: pointer;
    }
`;

function impersonate(id) {
    window.sessionStorage['impersonate'] = id;
    window.location = '/';
}

//const pros = {};

const headerIcon = () => {
    /*    if (thread.master) {
            if (thread.authorized)
                return <FaUnlockAlt onClick={this.toggleAuthorization} color="#c66"/>;
            else
                return <FaLock onClick={this.toggleAuthorization} color="#6c6"/>;
        }
        else*/
    return null;
};

class Messages extends Component {
    state = {
        message: ''
    };

    scrollToLastMessage = (force) => {
        const {messages} = this.props;

        if ((force || messages.length !== this.messageCount) && this.afterLastMessage) {
            this.afterLastMessage.scrollIntoView(this.messageCount === 0 ? null : {behavior: 'smooth'});

            this.messageCount = messages.length;
        }
    };

    messageHandler = e => {
        const message = e.target.value;

        this.setState({message});
    };

    render() {
        const {match: {params: {id:idString}}, history: {goBack}, user = {}, participant, messages} = this.props;
        const id = Number(idString);

        const thread = {};

        const {message} = this.state;

        return (
            <Wrapper>
                <Header
                    renderLeftIcon={<FaChevronLeft/>}
                    onLeftClick={goBack}
                />
                <Heading>
                    <HeadingContent>
                        {participant.firstName} {participant.lastName}
                        {headerIcon()}
                        {thread.authorized || user.admin ? (
                            <FaUserSecret onClick={() => impersonate(id)} color="#888"/>) : ''}
                    </HeadingContent>
                </Heading>
                <ScrollView>
                    {messages.map(message => {
                        const Message = message.recipientId===id ? Sent : Received;

                        return (
                            <Message key={message.id} message={message} onLoad={this.scrollToLastMessage}/>
                        );
                    })}
                    <AfterLastMessage innerRef={e => this.afterLastMessage = e}/>
                </ScrollView>
                <Form onSubmit={this.sendHandler}>
                    <CameraButton id={id} onSend={this.imageSent}/>
                    <Input name="message" value={message} onChange={this.messageHandler} autoComplete="off" autoFocus
                           placeholder="Type your message here."/>
                    <SendButton disabled={!message.trim()} onClick={this.sendHandler}/>
                </Form>
            </Wrapper>
        );
    }
}

const MESSAGES_QUERY = gql`
query Messages($id: Int!) {
    accountById(id: $id) {
        firstName
        lastName
    }

    messages(id: $id) {
        nodes {
            id
            recipientId
            message
            createdAt
        }
    }
}
`;

export default props => {
    const {match:{params:{id}}} = props;

    const {loading, data} = useQuery(MESSAGES_QUERY, {
        variables: id,
        fetchPolicy: 'cache-and-network'
    });

    if (loading)
        return <Spinner/>;

    const {
        accountById:participant,
        messages:{nodes:messages}
    } = data;

    return (
        <Messages {...props} participant={participant} messages={messages}/>
    );
};