import React, {Component} from 'react';
import styled from 'styled-components';
import {FaCamera} from 'react-icons/fa';
import gql from 'graphql-tag';

import Buttons from '../Buttons';
import Strut from '../Strut';
import Button from '../Button';
import Dialog from '../Dialog';
import Input from '../Input';
import Spinner from '../Spinner';
import {useMutation, useQuery} from "@apollo/react-hooks";

const FIELDS = ['quantity'];

const Photo = styled.div.attrs({
    children: ({image}) => !image ? <FaCamera/> : null
})`
    background-color: #ddd;
    background-image: ${({image}) => `url(${image})`};
    background-size: cover;
    background-position: center center;
    width: 200px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 30px;
    margin-bottom: .5em;
`;

const Form = styled.form`
    width: 200px;
`;

const Info = styled(({label, detail, ...rest}) => (
    <div {...rest}>
        <label>{label}</label>
        <div>{detail}</div>
    </div>
))`
    margin: .5em 0;
    
    label {
        display: block;
    }

    div {
        margin-left: 1em;
        display: flex;
        
        input {
            display: block;
            width: 100%;
        }
    }
`;

class GardenPlantSettings extends Component {
    state = {
        dirty: false,
        deleteHandler: null,
    };

    onSubmit = e => {
        e.preventDefault();

        const {dirty} = this.state;

        const {onClose} = this.props;

        if (dirty) {
            const form = e.target;

            const patch = {};

            FIELDS.forEach(key => patch[key] = form[key].value);

            const {update} = this.props;

            update(patch);
        }

        onClose()
    };

    onDelete = e => {
        e.preventDefault();

        this.setState({deleteHandler: () => {
                const {update, onClose} = this.props;

                update({endDate: new Date()});

                onClose();
            }});
    };

    update = e => {
        let form = e.target.parentNode;

        while (form && form.nodeName !== 'FORM')
            form = form.parentNode;

        const {gardenPlant} = this.props;

        let dirty = false;

        FIELDS.forEach(key => dirty = dirty || form[key].value !== String(gardenPlant[key]));

        if (this.state.dirty !== dirty)
            this.setState({dirty});
    };

    render() {
        const {gardenPlant, gardenPlant:{plant}} = this.props;

        const {dirty, deleteHandler} = this.state;

        return (
            <Form onSubmit={this.onSubmit}>
                <h3>{plant.commonName}</h3>
                <Photo image={plant.image.url}/>
                <Info label="Quantity" detail={<Input name="quantity" defaultValue={gardenPlant.quantity} onChange={this.update} required pattern="^[1-9]\d*$"/>}/>
                {plant.rotation && <Info label="Rotation" detail={plant.rotation.name}/>}
                {plant.notes && <Info label="Notes" detail={plant.notes}/>}
                <Button>{dirty ? 'Save' : 'Close'}</Button>
                <Button to={`/plant-care/${encodeURIComponent(plant.wikipediaTitle)}`}>Plant Care</Button>
                <Strut/>
                <Button onClick={this.onDelete} type="warn">Delete Plant</Button>
                {deleteHandler && (
                    <Dialog>
                        <h3>Delete Plant</h3>
                        <p>Are you sure you want to delete this plant?</p>
                        <Buttons>
                            <Button type="warn" onClick={deleteHandler}>Yes</Button>
                            <Button onClick={() => this.setState({deleteHandler:null})}>No</Button>
                        </Buttons>
                    </Dialog>
                )}
            </Form>
        )
    }
}

const GARDEN_PLANT_QUERY = gql`
query GardenPlant($id: UUID!) {
    gardenPlant(id: $id) {
        id
        plant {
            id
            commonName
            image {
                url
            }
            rotation {
                name
            }
            notes
            wikipediaTitle
        }
        quantity
    }
}
`;

const UPDATE_GARDEN_PLANT = gql`
mutation UpdateGardenPlant($input: UpdateGardenPlantByIdInput!) {
    updateGardenPlantById(input: $input) {
        gardenPlant {
            id
            quantity
            endDate
        }
    } 
}
`;

const UpdateGardenPlant = props => {
    const [mutation] = useMutation(UPDATE_GARDEN_PLANT);

    const {gardenPlant:{id}} = props;

    const update = patch => mutation({
        variables: {
            input: {
                id,
                gardenPlantPatch: patch
            }
        }
    });

    return (
        <GardenPlantSettings update={update} {...props}/>
    );
};

export default props => {
    const {id} = props;

    const {loading, data} = useQuery(GARDEN_PLANT_QUERY, {
        variables: {
            id
        }
    });

    if (loading) {
        return (
            <Spinner/>
        );
    }

    const {gardenPlant} = data;

    return (
        <UpdateGardenPlant gardenPlant={gardenPlant} {...props}/>
    );
};