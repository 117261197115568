import React from 'react';
import styled from 'styled-components';

import Item from './Item';
import Plant from './Plant';

const PlantList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
`;

export default ({plants, onSelect, children}) => (
    <PlantList>
        {plants.map(plant => {
            return (
                <Item key={(plant.plant || plant).id}>
                    <Plant plant={plant} onSelect={onSelect}/>
                </Item>
            );
        })}
        {children && (
            <Item key="children">
                {children}
            </Item>
        )}
    </PlantList>
);