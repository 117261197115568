import React, {Component, useState} from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';

import api from '../api';
import Header from '../component/Header';
import Heading from '../component/Heading';
import Input from '../component/Input';
import Buttons from '../component/Buttons';
import Button from '../component/Button';
import Error from '../component/Error';
import {useMutation} from "@apollo/react-hooks";

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Center = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

// const DEFAULT_STATE = {
//     inFlight: false,
//     error: null
// };

const Link = styled(Button)`
    text-decoration: none;
`;

const Login = props => {
    const [loginMutation, {loading:loggingIn}] = useMutation(LOGIN_MUTATION);
    const [registerAccountMutation, {loading:registering}] = useMutation(REGISTER_ACCOUNT_MUTATION);

    const [showRegistration, setShowRegistration] = useState(false);
    const [error, setError] = useState(null);

    const inFlight = loggingIn || registering;

    const login = e => {
        e.preventDefault();

        const form = e.target;

        const email = form.email.value;
        const password = form.password.value;

        loginMutation({
            variables: {
                input: {
                    email,
                    password
                }
            }
        })
            .then(response => {
                const {data:{login:{jwtToken:jwt}}} = response;

                if (!jwt) {
                    setError('Invalid Email or Password!')
                }
                else {
                    window.localStorage.setItem('jwt', jwt);

                    const {history, onLogin} = props;

                    onLogin();
                    history.push('/');
                }
            });
    }

    /*login = e => {
        e.preventDefault();

        const form = e.target;

        const email = form.email.value;
        const password = form.password.value;

        this.setState({
            showRegistration: false,
            inFlight: true,
            error: null
        });

        api('login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        })
            .then(response => response.json())
            .then(({error, jwt}) => {
                if (jwt) {
                    window.localStorage.setItem('jwt', jwt);

                    const {history, onLogin} = this.props;

                    onLogin();
                    history.push('/');
                }
                else
                    this.setState({
                        inFlight: false,
                        error: error || 'Invalid response from server!'
                    });
            })
            .catch(e => console.error(e))
    };*/

    const register = e => {
        e.preventDefault();

        const form = e.target;

        const firstName = form.firstName.value;
        const lastName = form.lastName.value;
        const email = form.email.value;
        const password = form.password.value;

        registerAccountMutation({
            variables: {
                input: {
                    firstName,
                    lastName,
                    email,
                    password
                }
            }
        }).then(response => {
            console.log(response);

            const {data:{registerAccount:{jwtToken:jwt}}} = response;

            if (!jwt) {
                setError('Invalid Email or Password!')
            }
            else {
                window.localStorage.setItem('jwt', jwt);

                const {history, onLogin} = props;

                onLogin();
                history.push('/');
            }
        });

        setError('Not implemented yet!');
    };

    const toggleRegistration = () => setShowRegistration(!showRegistration);

    const renderLoginForm = () => {
        return (
            <Wrapper>
                <Header/>
                <Center>
                    {error && <Error>{error}</Error>}
                    <Form onSubmit={login}>
                        <Heading>Sign in to your account</Heading>
                        <Input key="email" type="email" name="email" placeholder="Email Address" required={true}/>
                        <Input key="password" type="password" name="password" placeholder="Password" required={true}/>
                        <Buttons>
                            <Button disabled={inFlight}>Sign In</Button>
                        </Buttons>
                    </Form>
                </Center>
                <Buttons>
                    <Button type="subtle" onClick={toggleRegistration}>Create Account</Button>
                </Buttons>
            </Wrapper>
        );
    }

    const renderRegistrationForm = () => {
        return (
            <Wrapper>
                <Header/>
                <Center>
                    {error && <Error>{error}</Error>}
                    <Form onSubmit={register}>
                        <Heading>Create an account</Heading>
                        <Input key="firstName" type="text" name="firstName" placeholder="First Name" required={true}/>
                        <Input key="lastName" type="text" name="lastName" placeholder="Last Name" required={true}/>
                        <Input key="email" type="email" name="email" placeholder="Email Address" required={true}/>
                        <Input key="password" type="password" name="password" placeholder="Password" required={true}/>
                        <Buttons>
                            <Button disabled={inFlight}>Create Account</Button>
                        </Buttons>
                    </Form>
                </Center>
                <Buttons>
                    <Button type="subtle" onClick={toggleRegistration}>Sign In</Button>
                </Buttons>
            </Wrapper>
        );
    }

    if (showRegistration)
        return renderRegistrationForm();
    else
        return renderLoginForm();
}

export default Login;

const LOGIN_MUTATION = gql`
    mutation Login($input: LoginInput!) {
        login(input: $input) {
            jwtToken
        }
    }
`;

const REGISTER_ACCOUNT_MUTATION = gql`
    mutation RegisterAccount($input: RegisterAccountInput!) {
        registerAccount(input: $input) {
            jwtToken
        }
    }
`;