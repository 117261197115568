import React, {Component} from 'react';
import styled from 'styled-components';
import {Route} from 'react-router-dom';

import Button from '../component/Button';

import EditPlant from '../component/admin/EditPlant';
import Users from '../component/admin/Users';

const Wrapper = styled.div`
    flex: 1;
    display: flex;
`;

const Menu = styled(({className}) => (
    <Wrapper>
        <div className={className}>
            <h1>Admin Tools</h1>
            <Button to="/admin/users">Users</Button>
            <Button to="/admin/edit-plant">Edit Plants</Button>
        </div>
    </Wrapper>
))`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    ${Button} {
        width: 250px;
    }
`;

class Admin extends Component {
    render() {
        const {match} = this.props;

        return (
            <Wrapper>
                <Route exact path={match.url} component={Menu}/>
                <Route path={`${match.url}/users`} component={Users}/>
                <Route path={`${match.url}/edit-plant`} component={EditPlant}/>
            </Wrapper>
        );
    }
}

export default Admin;
