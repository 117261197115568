import React, {Component} from 'react';
import styled from 'styled-components';

const Select = styled.select`
    border: none;
    font-size: ${({theme:{input:{size}}}) => size};
    margin: 5px 0;
    padding: 4px 8px;
    border-bottom: 1px solid #999;
    background: transparent;
`;

class SelectWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.defaultValue
        };
    }

    onChange = e => {
        this.setState({value: e.target.value});

        this.value = e.target.value;
    };

    render() {
        const {children, ...props} = this.props;
        const {value} = this.state;

        return (
            <Select value={value} onChange={this.onChange} {...props}>
                {children}
            </Select>
        )
    }
}

export default SelectWrapper;