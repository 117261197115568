import React from 'react';
import styled from 'styled-components';

import PlantIcon from './PlantIcon';

const Option = styled.div`
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: ${({belowGround}) => belowGround ? '3px' : '25px'};
    cursor: pointer;
    position: relative;
    
    &:hover {
        background: ${({theme:{hover:{background}}}) => background};
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;

const Ground = styled.div`
    background: #630;
    height: 25px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
`;

const PlantStage = ({onSelect}) => (
    <Container>
        <Ground/>
        <Option belowGround={true} onClick={() => onSelect(0)}><PlantIcon stage={0} size={20}/></Option>
        <Option onClick={() => onSelect(1)}><PlantIcon stage={1} size={30}/></Option>
        <Option onClick={() => onSelect(2)}><PlantIcon stage={2} size={60}/></Option>
        <Option onClick={() => onSelect(3)}><PlantIcon stage={3} size={80}/></Option>
        <Option onClick={() => onSelect(4)}><PlantIcon stage={4} size={80}/></Option>
    </Container>
);

export default PlantStage;