import React, {Component} from 'react';

import api from '../api';

import Spinner from './Spinner';

const CACHE = {};

class Api extends Component {
    constructor(props, ...args) {
        super(props, ...args);

        const {path} = props;

        this.key = props.key || 'data';

        const data = CACHE[path] || null;

        this.state = {
            [this.key]: data
        };
    }

    componentDidMount() {
        if (!this.state[this.key])
            this.refresh();
    }

    refresh = () => {
        const {path} = this.props;

        api(path)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    [this.key]: data
                });

                const {cache=true} = this.props;

                if (cache)
                    CACHE[path] = data;
            });
    };

    render() {
        const {render} = this.props;

        const data = this.state[this.key];

        if (!data) {
            return (
                <Spinner/>
            );
        }

        return render({data, refresh: this.refresh});
    }
}

export default Api;