import React from 'react';
import styled, {keyframes} from 'styled-components';

const fade = keyframes`
    from { opacity: 0.1; }
    to { opacity: 1.0; }
`;

const Spinner= styled.div.attrs({
    children: (<div>Loading...</div>)
})`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        font-size: 32px;
        color: #666;
        opacity: 0.1;
        
        margin: 10px 0;
        
        animation: ${fade} 500ms alternate infinite;
    }
`;

export default Spinner;