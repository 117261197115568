import React, {useState} from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import {useHistory} from 'react-router-dom';

import Buttons from '../Buttons';
import Button from '../Button';
import Strut from '../Strut';
import Dialog from '../Dialog';
import Spinner from "../Spinner";
import {useMutation, useQuery} from "@apollo/react-hooks";

const MICROCLIMATES = {
    A: {
        image: require('../../image/microclimate/a.png'),
        imageHeight: 225,
        location: 'Morning Sun, Afternoon Shade',
        description: 'Morning Sun and Afternoon Shade',
        info: 'Great for Herbs and Heat-sensitives'
    },
    B: {
        image: require('../../image/microclimate/b.png'),
        imageHeight: 230,
        location: 'Full Sun = 6+ Hours',
        description: 'Morning and Afternoon Sun',
        info: 'Traditional Growing Location'
    },
    C: {
        image: require('../../image/microclimate/c.png'),
        imageHeight: 220,
        location: 'Morning Shade, Afternoon Sun',
        description: 'Morning Shade and Afternoon Sun',
        info: 'Start Your Spring Early & Extend Your Fall Harvest'
    },
    D: {
        image: require('../../image/microclimate/d.png'),
        imageHeight: 218,
        location: 'Receives Late Afternoon Sun Only',
        description: 'Morning Shade and Partial Afternoon Sun',
        info: 'Sweet Greens all Summer'
    },
    E: {
        image: require('../../image/microclimate/e.png'),
        imageHeight: 225,
        location: 'Filtered Light between 15-65% Shade',
        description: 'Filtered/Reflective Morning and Afternoon Sun',
        info: 'Extend your Tender Greens into Summer Heat and More'
    },
    F: {
        image: require('../../image/microclimate/f.png'),
        imageHeight: 216,
        location: 'Full Shade',
        description: 'Morning and Afternoon Shade',
        info: 'Great for Summer Greens, Edible Ferns & more Shade-lovers'
    }
};

const Location = styled.div`
    color: ${({theme:{subtle:{color}}}) => color};
`;

/*const Description = styled.div`
    color: #990;
`;*/

const Info = styled.div`
    color: ${({theme:{highlight:{color}}}) => color};
`;

const MicroclimateInfo = styled(({microclimate, className, style, mapHandler}) => {
    const {image, imageHeight, location, info/*, description*/} = MICROCLIMATES[microclimate];

    return (
        <div className={className} style={style}>
            <map name="microclimatemap">
                <area shape="rect" coords="32,74,102,144" href="#A" alt="Microclimate A" onClick={mapHandler}/>
                <area shape="rect" coords="103,74,174,144" href="#B" alt="Microclimate B" onClick={mapHandler}/>
                <area shape="rect" coords="175,74,242,144" href="#C" alt="Microclimate C" onClick={mapHandler}/>
                <area shape="rect" coords="175,2,242,70" href="#D" alt="Microclimate D" onClick={mapHandler}/>
                <area shape="rect" coords="103,2,174,70" href="#E" alt="Microclimate E" onClick={mapHandler}/>
                <area shape="rect" coords="32,2,102,70" href="#F" alt="Microclimate F" onClick={mapHandler}/>
            </map>
            <img src={image} width={269} height={imageHeight} alt={`Microclimate ${microclimate}`} useMap="#microclimatemap"/>
            <Location>{location}</Location>
            <Info>{info}</Info>
        </div>
    );
})`
    margin-top: 2px;
    width: 269px;
    
    & div {
        margin: 10px 0;
        text-align: center;
    }
    
    & area {
        outline: none;
    }
`;


const Form = styled.form`
    label > div {
        font-size: 75%;
    }
`;

const GardenSettings = ({garden, update, onClose}) => {
    const history = useHistory();

    const [fields, setFields] = useState({
        name: garden.name,
        zip: garden.zip,
        microclimate: garden.microclimate
    });

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const dirty = !Object.keys(fields).every(key => fields[key] === garden[key]);

    const onSubmit = e => {
        e.preventDefault();

        if (dirty) {
            update(fields);
        }

        if (garden.id)
            onClose();
    };

    const onDelete = e => {
        e.preventDefault();

        setShowDeleteDialog(true);

        /*const {deleteZone, zone, loadTasks} = this.props;
        const {router:{history}} = this.context;

        if (!zone.id) {
            history.goBack();
        }
        else {
            this.setState({
                deleteHandler: () => {
                    if (zone.id) {
                        deleteZone(zone)
                            .then(() => {
                                loadTasks();
                            });
                    }

                    history.goBack();
                }
            });
        }*/
    };

    const changeHandler = e => {
        const {name, value} = e.target;
        setFields(Object.assign({}, fields, {
            [name]: value
        }));
    };

    const mapHandler = e => {
        e.preventDefault();

        const selection = e.target.href.replace(/.*#/, '');

        setFields(Object.assign({}, fields, {
            microclimate: selection
        }));
    };

    const deleteHandler = e => {

    };

    return (
        <Form onSubmit={onSubmit}>
            <h3>Garden Settings</h3>
            <label>Name Your Garden</label>
            <input name="name" type="text" value={fields.name} required onChange={changeHandler}/>
            <label>ZIP Code</label>
            <input name="zip" type="number" value={fields.zip} required onChange={changeHandler}/>
            <label>
                Select Microclimate
                <div>(touch a zone for description)</div>
            </label>
            <input name="microclimate" type="hidden" value={fields.microclimate}/>
            <MicroclimateInfo microclimate={fields.microclimate} mapHandler={mapHandler}/>
            <Button>{dirty || !garden.id ? 'Save Zone' : 'Close'}</Button>
            <Strut/>
            <Button onClick={onDelete} type="warn">{garden.id ? 'Delete Garden' : 'Cancel'}</Button>
            {showDeleteDialog && (
                <Dialog>
                    <h3>Delete Garden</h3>
                    <p>Are you sure you want to delete this garden?</p>
                    <Buttons>
                        <Button type="warn" onClick={deleteHandler}>Yes</Button>
                        <Button onClick={() => setShowDeleteDialog(false)}>No</Button>
                    </Buttons>
                </Dialog>
            )}
        </Form>
    );
};

const UPDATE_GARDEN = gql`
mutation UpdateGarden($input: UpdateGardenByIdInput!) {
    updateGardenById(input: $input) {
        garden {
            id
            name
            microclimate
            zip
        }
    } 
}
`;

const UpdateGarden = props => {
    const [mutation] = useMutation(UPDATE_GARDEN);

    const {garden:{id}} = props;

    const update = patch => mutation({
        variables: {
            input: {
                id,
                gardenPatch: patch
            }
        }
    });

    return (
        <GardenSettings update={update} {...props}/>
    );
};

const GARDEN_QUERY = gql`
query Garden($id: UUID!) {
    garden(id: $id) {
        id
        name
        microclimate
        zip
        image {
          url
        }
    }
}
`;

export default props => {
    const {
        id
    } = props;

    if (id === 'new') {
        const newGarden = {
            microclimate:'B',
            location:localStorage.getItem('lastLocation')
        };

        return (
            <GardenSettings garden={newGarden} {...props}/>
        );
    }

    const {loading, data} = useQuery(GARDEN_QUERY, {
        variables: {
            id
        }
    });

    if (loading) {
        return (
            <Spinner/>
        );
    }

    const {garden} = data;

    return (
        <UpdateGarden garden={garden} {...props}/>
    );
};
