import React from 'react';
import styled from 'styled-components';
import {FaCog,FaPlusCircle} from 'react-icons/fa';
import gql from 'graphql-tag';

import Popup from '../../component/Popup';
import Drawer from '../../component/Drawer';
import {GardenSettings, GardenPlantSettings} from '../../component/garden/index';
import Heading from '../../component/Heading';
import PlantList from '../../component/PlantList/index';
import ScrollView from '../../component/ScrollView';
import Spinner from '../../component/Spinner';
import {useQuery} from "@apollo/react-hooks";

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Name = styled.div`
`;

const Microclimate = styled.div`
    font-size: 24px;
    font-weight: normal;
`;

const AddPlant = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;

    color: ${({theme:{highlight:{color}}}) => color};
    
    & .icon {
        font-size: 75px;
    }
`;

class Garden extends Popup {
    state = {
        drawer: null
    };

    renderRightIcon() {
        return <FaCog/>
    }

    onRightClick() {
        this.showGardenSettings();
    }

    showGardenSettings() {
        this.setDrawer(<GardenSettings id={this.props.garden.id} onClose={this.closeDrawer}/>);
    };

    closeDrawer = () => {
        if (this.props.garden)
            this.setDrawer(null);
    };

    setDrawer = drawer => {
        this.setState({drawer});
    };

    showGardenPlantSettings = gardenPlant => {
        this.setDrawer(
            <GardenPlantSettings
                id={gardenPlant.id}
                onClose={this.closeDrawer}
            />
        );
    };

    renderContent() {
        const {
            history: {
                location: {
                    pathname
                }
            },
            history,
            garden
        } = this.props;

        const {drawer} = this.state;


        const gardenPlants = garden.gardenPlants.nodes
            .filter(gp => !gp.endDate);

        gardenPlants.sort(plantCompare);

        return (
            <Content>
                <Heading>
                    <Name>{garden.name}</Name>
                    <Microclimate>Microclimate {garden.microclimate}</Microclimate>
                </Heading>
                <ScrollView>
                    <PlantList plants={gardenPlants} onSelect={this.showGardenPlantSettings}>
                        <AddPlant
                            onClick={() => history.push(`${pathname}/add-plant`)}
                        >
                            <FaPlusCircle key="icon" className="icon"/>,
                            <div key="label">Add Plant</div>
                        </AddPlant>
                    </PlantList>
                </ScrollView>
                <Drawer show={!!drawer} onClose={this.closeDrawer}>
                    {drawer}
                </Drawer>
            </Content>
        );
    }
}

const GARDEN_QUERY = gql`
query Garden($id: UUID!) {
    garden(id: $id) {
        id
        name
        microclimate
        zip
        image {
          url
        }
        gardenPlants(condition:{endDate:null}) {
          nodes {
            id
            plant {
              id
              commonName
              image {
                url
              }
            }
            quantity
            endDate
          }
        }
    }
}
`;

export default props => {
    const {
        match: {
            params: {
                id
            }
        }
    } = props;

    if (id === 'new') {
        const newGarden = {
            microclimate: 'B',
            nodes: [],
            zip: localStorage.getItem('lastLocation')
        };

        return (
            <Garden garden={newGarden} gardenPlants={[]} {...props}/>
        );
    }

    const {loading, data} = useQuery(GARDEN_QUERY, {
        variables: {
            id
        }
    });

    if (loading) {
        return (
            <Spinner/>
        );
    }

    const {garden} = data;

    return (
        <Garden garden={garden} {...props}/>
    )
};

function plantCompare(a, b) {
    const n1 = a.plant.commonName.toLowerCase();
    const n2 = b.plant.commonName.toLowerCase();
    if (n1 < n2)
        return -1;
    else if (n1 > n2)
        return 1;
    else
        return 0;
}