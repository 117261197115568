import React, {createElement} from 'react';
import styled from 'styled-components';

export const IconWrapper = styled.div`
    color: ${({color}) => color || '#666'};
`;

const ListItemWrapper = styled.div`
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ListItemLabel = styled.div`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${({highlight}) => highlight ? 'bold' : 'inherit'};
    color: ${({highlight, highlightColor}) => highlight ? highlightColor : 'inherit'};
    
    @media (max-width: 420px) {
        font-size: 3.5vw;
    }
`;

const ListItem = ({children, icon, iconColor, onClick, highlight}) => {
    return (
        <ListItemWrapper onClick={onClick}>
            <ListItemLabel highlight={highlight}>{children}</ListItemLabel>
            <IconWrapper>
                {icon && createElement(icon, {color: iconColor})}
            </IconWrapper>
        </ListItemWrapper>
    )
};

export default ListItem;