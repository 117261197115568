import React, {createElement} from 'react';
import styled from 'styled-components';
import {NavLink, withRouter} from 'react-router-dom';
import {FaList,FaComment,FaLeaf,FaSignOutAlt,FaBan,FaUserSecret} from 'react-icons/fa';
import gql from 'graphql-tag';
import {useQuery} from "@apollo/react-hooks";

const Wrapper = styled.div`
    flex-shrink: 0;
    display: flex;
    border-top: 1px solid #999;
`;

const StyledLink = styled(NavLink)`
    position: relative;
    font-size: 66%;
    text-decoration: none;
    flex: 1;
    text-align: center;
    
    background-color: ${({theme:{tab:{background}}}) => background};
    color: ${({theme:{tab:{color}}, disabled}) => disabled ? '#ccc' : color};
    
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
    
    &.active {
        background-color: ${({theme:{tab:{selected:{background}}}}) => background};
        color: ${({theme:{tab:{selected:{color}}}}) => color};
    }
`;

const IconWrapper = styled.div`
    font-size: 250%;
    margin-bottom: 4px;
`;

const Indicator = styled.div`
    position: absolute;
    top: .5em;
    left: 50%;
    margin-left: 1.5em;
    background-color: #f00;
    color: #fff;
    width: 2em;
    height: 2em;
    line-height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;

function ignore(e) {
    e.preventDefault();
}

const Tab = ({to, icon, label, indicator, disabled}) => {
    const isActive = to === '/' ?
        (matches, {pathname}) => {
            return pathname === '/' || pathname.match(/^\/my-garden/)
        } : null;

    return (
        <StyledLink to={to} activeClassName="active" isActive={isActive} disabled={disabled} onClick={disabled ? ignore : null}>
            <IconWrapper>
                {createElement(icon)}
            </IconWrapper>
            {label}
            {indicator && <Indicator>{indicator}</Indicator>}
        </StyledLink>
    );
};

const MyAccount = styled(({className}) => (
    <div className={className}>
        <FaBan color="rgba(255,0,0,.75)" size={32}/>
        <FaUserSecret size="20"/>
    </div>
))`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        transform: translateY(3px);
    }
    
    svg:first-child {
        position: relative;
        z-index: 2;
    }
    
    svg:last-child {
        position: absolute;
        z-index: 1;
    }
`;

const FOOTER_QUERY = gql`
{
    viewer {
        pro
        admin
        messageCounts {
            nodes {
                unread
            }
        }
    }
}
`;

const Footer = () => {
    const impersonating = !!window.sessionStorage['impersonate'];

    const {data:{viewer}={viewer:{messageCounts:{nodes:[]}}}} = useQuery(FOOTER_QUERY);

    const unread = viewer.messageCounts.nodes.reduce((total, {unread}) => total + unread, 0);

    return (
        <Wrapper>
            <Tab to="/gardens" label="My Garden" icon={FaList}/>
            <Tab to="/messages/" label={viewer.master ? 'Messages' : 'Ask A Pro'} icon={FaComment} indicator={unread} disabled={impersonating}/>
            <Tab to="/plant-care/" label="Plant Care" icon={FaLeaf} disabled={impersonating}/>
            <Tab to="/logout" label={impersonating ? 'My Account' : 'Log Out'} icon={impersonating ? MyAccount : FaSignOutAlt}/>
        </Wrapper>
    );
};

export default withRouter(Footer);