import React from 'react';
import styled from 'styled-components';

const IMAGE = [
    require('./image/seed.svg'),
    require('./image/seedling.svg'),
    require('./image/start.svg'),
    require('./image/flowering.svg'),
    require('./image/fruiting.svg')
];

const Image = styled.img`
    height: ${({height}) => height}px;
    width: auto;
    
    path {
        fill: #f00;
    }
`;

const PlantIcon = styled.div.attrs({
    children: ({stage=0, size=30}) => <Image src={IMAGE[stage]} height={stage === 0 ? size / 2 : size}/>
})`
    width: auto;
    height: ${({size=30}) => size}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default PlantIcon;