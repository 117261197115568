import React from 'react';
import styled from 'styled-components';

import Header from '../component/Header';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Wunderground = styled.iframe.attrs({
    src: 'https://www.wunderground.com',
    frameBorder: 0,
    marginHeight: 0,
    marginWidth: 0,
    scroll: 'no'
})`
    width: 100%;
    flex-grow: 1;
`;

const Weather = props => (
    <Wrapper>
        <Header/>
        <Wunderground/>
    </Wrapper>
);

export default Weather;